import React, { useEffect } from 'react';
import '../App.css';
import styled from 'styled-components';

const Section = styled.section`
  margin-bottom: 60px; // Increased margin between sections
`;

const Legal = () => {
  const lastUpdated = "August 17, 2024";

  useEffect(() => {
    // Function to handle smooth scrolling
    const handleScroll = () => {
      const hash = window.location.hash;
      if (hash) {
        setTimeout(() => {
          const element = document.querySelector(hash);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 0);
      }
    };

    // Call the function when component mounts
    handleScroll();

    // Add event listener for hash changes
    window.addEventListener('hashchange', handleScroll);

    // Clean up the event listener
    return () => window.removeEventListener('hashchange', handleScroll);
  }, []);

  return (
    <div className="legal-page">
      <h1>Legal Information</h1>

      <Section id="privacy-policy">
        <h2 id="privacy-policy-title">Watch10k Privacy Policy</h2>
        <p>Last Updated: {lastUpdated}</p>

        <p>This Privacy Policy explains how Minor Role Inc. ("we," "us," or "our") handles data in relation to our Watch10k application ("the App") on Apple Watch. Watch10k is developed by Minor Role Inc. Please read this Privacy Policy carefully.</p>

        <h3>1. Information We Use</h3>
        <h4>1.1 Health and Fitness Data</h4>
        <p>The App accesses step count data from Apple's HealthKit framework. This data is used to track your progress towards your daily step goal and maintain your streak information. This data remains on your device and in your personal iCloud account.</p>
        <h4>1.2 Usage Data</h4>
        <p>The App may collect basic usage information such as app launches and crash data to improve app performance. This data is anonymized and cannot be linked to individual users.</p>

        <h3>2. How We Use Your Information</h3>
        <h4>2.1 To Provide App Functionality</h4>
        <p>We use your step count data to operate the App's core features, including tracking your step count, calculating streaks, and displaying progress.</p>
        <h4>2.2 Notifications</h4>
        <p>The App uses your step count data to send you motivational notifications and reminders to help you reach your step goal. These notifications are generated and sent locally from your device.</p>
        <h4>2.3 Apple Watch Complications</h4>
        <p>We use your step count data to update complications on your Apple Watch face.</p>

        <h3>3. Data Storage and Sync</h3>
        <h4>3.1 Local Storage</h4>
        <p>Your streak information and goal completion status are stored locally on your device using CoreData.</p>
        <h4>3.2 CloudKit Sync</h4>
        <p>The App uses Apple's CloudKit to sync your data across your Apple devices and provide backup functionality. This data is stored securely in your personal iCloud account and is not accessible to us.</p>

        <h3>4. Data Sharing</h3>
        <h4>4.1 No Third-Party Sharing</h4>
        <p>We do not share your personal data with any third parties, including for marketing purposes.</p>
        <h4>4.2 No Data Collection</h4>
        <p>We do not collect or store your personal data on our servers. All your data remains on your device or in your personal iCloud account.</p>

        <h3>5. Data Security</h3>
        <p>Your data's security is managed by Apple's built-in security features for local device storage and iCloud. We do not handle or store your data separately.</p>

        <h3>6. Your Rights and Choices</h3>
        <h4>6.1 Access and Control</h4>
        <p>You can view, edit, or delete your health and fitness data through the Apple Health app on your iOS device.</p>
        <h4>6.2 Notifications</h4>
        <p>You can manage notification settings for the App through your device settings.</p>
        <h4>6.3 Data Deletion</h4>
        <p>If you wish to delete all data associated with the App, you can delete the App from your device. This will remove all locally stored data. To remove data from CloudKit, you can manage this through your iCloud settings.</p>

        <h3>7. Changes to This Privacy Policy</h3>
        <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date.</p>

        <h3>8. Contact Us</h3>
        <p>If you have any questions about this Privacy Policy, please contact us at:</p>
        <p>
          Minor Role Inc.<br />
          general@minorrole.com<br />
          470-681-8911
        </p>
      </Section>
      
      <Section id="terms-of-use">
      <h2 id="terms-of-use-title">Watch10k Terms of Use</h2>
        <p>Last Updated: {lastUpdated}</p>

        <h3>1. Agreement to Terms</h3>
        <p>By downloading, installing, or using our Watch10k application ("the App") on Apple Watch, you agree to be bound by these Terms of Use ("Terms"). If you do not agree to these Terms, do not use the App. Watch10k is developed and operated by Minor Role Inc. ("we," "us," or "our").</p>

        <h3>2. Changes to Terms</h3>
        <p>We reserve the right to modify these Terms at any time. We will notify you of any changes by updating the "Last Updated" date of these Terms and, where appropriate, via a pop-up notice in the App. Your continued use of the App following the posting of changes constitutes your acceptance of such changes.</p>

        <h3>3. Privacy Policy</h3>
        <p>Our Privacy Policy is incorporated by reference into these Terms. Please review our Privacy Policy for information on how we handle your data.</p>

        <h3>4. Use of the App</h3>
        <h4>4.1 Health and Fitness Data</h4>
        <p>The App accesses step count data from Apple's HealthKit framework. This data is used to track your progress towards your daily step goal of 10,000 steps and maintain your streak information. The 10,000 steps goal is fixed and cannot be changed by users.</p>

        <h4>4.2 Streak Calculation</h4>
        <p>A "streak" in Watch10k is defined as the number of consecutive days you reach or exceed the 10,000 steps goal. Your streak is calculated as follows:</p>
        <ul>
          <li>If you reach or exceed 10,000 steps in a day, your streak increases by one day.</li>
          <li>If you don't reach 10,000 steps in a day, your streak resets to zero.</li>
          <li>The streak is updated once per day, typically at midnight local time.</li>
          <li>If the app is not opened or unable to fetch data for a particular day, it may affect your streak calculation.</li>
        </ul>

        <h4>4.3 Notifications</h4>
        <p>The App may send you motivational notifications and reminders based on your step count data. These notifications are generated and sent locally from your device.</p>

        <h4>4.4 Apple Watch Complications</h4>
        <p>We use your step count data to update complications on your Apple Watch face. These complications provide at-a-glance information about your daily step progress.</p>

        <h4>4.5 Data Storage and Sync</h4>
        <p>Your streak information and goal completion status are stored locally on your device using CoreData and synced across your Apple devices using Apple's CloudKit.</p>

        <h4>4.6 Background Refresh</h4>
        <p>The App uses background refresh capabilities to update your step count and complications even when the App is not actively in use.</p>

        <h4>4.7 Data Visualization</h4>
        <p>The App provides visual representations of your step count data, including a circular progress view for your daily goal and a line graph showing your step counts for the past six days.</p>
        <h3>5. Privacy and Data Handling</h3>
        <p>We take the privacy and security of your health data seriously. Here's how we handle your data:</p>
        <ul>
          <li>Step count data is read from HealthKit but is not stored permanently by our app.</li>
          <li>Your current streak and daily goal completion status are stored locally on your device and synced across your Apple devices using Apple's CloudKit.</li>
          <li>We do not have access to this data on our servers.</li>
          <li>The app does not collect or store any personally identifiable information.</li>
          <li>We do not share your health data with any third parties.</li>
          <li>All data processing occurs on your device.</li>
          <li>The security of your data is managed by Apple's built-in security features for local device storage and iCloud.</li>
        </ul>
        <p>You can revoke the app's access to your HealthKit data at any time through your device's Settings app.</p>

        <h3>6. User Responsibilities</h3>
        <p>You agree to:</p>
        <ul>
          <li>Provide accurate and complete information when using the App.</li>
          <li>Maintain the security of your Apple ID and password.</li>
          <li>Use the App in compliance with all applicable laws and regulations.</li>
        </ul>
        <p>You agree not to:</p>
        <ul>
          <li>Use the App for any unlawful purpose or in any way that interrupts, damages, or impairs the App.</li>
          <li>Attempt to gain unauthorized access to any part of the App or any system or network connected to the App.</li>
          <li>Use any automated means to access or use the App.</li>
        </ul>

        <h3>7. Intellectual Property Rights</h3>
        <p>The App and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof) are owned by Minor Role Inc. and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</p>

        <h3>8. Disclaimers</h3>
        <p>THE APP IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.</p>
        <p>We do not warrant that the App will function uninterrupted, secure or available at any particular time or location, or that any errors or defects will be corrected. We do not warrant the accuracy, completeness, or usefulness of any information provided through the App.</p>

        <h3>9. Limitation of Liability</h3>
        <p>TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT SHALL MINOR ROLE INC., ITS DIRECTORS, EMPLOYEES, PARTNERS, AGENTS, SUPPLIERS, OR AFFILIATES BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING WITHOUT LIMITATION, LOSS OF PROFITS, DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM (i) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE APP; (ii) ANY CONDUCT OR CONTENT OF ANY THIRD PARTY ON THE APP; (iii) ANY CONTENT OBTAINED FROM THE APP; AND (iv) UNAUTHORIZED ACCESS, USE OR ALTERATION OF YOUR TRANSMISSIONS OR CONTENT, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE) OR ANY OTHER LEGAL THEORY, WHETHER OR NOT WE HAVE BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE.</p>

        <h3>10. Indemnification</h3>
        <p>You agree to defend, indemnify, and hold harmless Minor Role Inc., its parent company, officers, directors, employees, and agents, from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms or your use of the App.</p>

        <h3>11. Third-Party Services</h3>
        <p>The App uses Apple's HealthKit, CloudKit, and other services. Your use of these services is subject to their respective terms and conditions.</p>

        <h3>12. Termination</h3>
        <p>We may terminate or suspend your access to the App immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach these Terms. Upon termination, your right to use the App will immediately cease.</p>

        <h3>13. Governing Law</h3>
        <p>These Terms shall be governed and construed in accordance with the laws of [Your State/Country], without regard to its conflict of law provisions. Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights.</p>

        <h3>14. Changes to the App</h3>
        <p>We reserve the right to withdraw or amend the App, and any service or material we provide via the App, in our sole discretion without notice. We will not be liable if, for any reason, all or any part of the App is unavailable at any time or for any period.</p>

        <h3>15. Data Deletion</h3>
        <p>If you wish to delete all data associated with Watch10k:</p>
        <ol>
          <li>You can delete the app from your device, which will remove all locally stored data.</li>
          <li>To remove data synced to CloudKit, you can manage this through your iCloud settings.</li>
          <li>To revoke the app's access to your HealthKit data, go to Settings {'>'} Privacy {'>'} Health {'>'} Watch10k and turn off all permissions.</li>
        </ol>
        <p>Please note that we do not store any of your personal data on our servers, so there is no need to contact us for data deletion.</p>

        <h3>16. Severability</h3>
        <p>If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law, and the remaining provisions will continue in full force and effect.</p>

        <h3>17. Entire Agreement</h3>
        <p>These Terms, our Privacy Policy, and any amendments and additional agreements you might enter into with Minor Role Inc. in connection with the App, shall constitute the entire agreement between you and Minor Role Inc. concerning the App.</p>

        <h3>18. Contact Information</h3>
        <p>For any questions about these Terms, please contact us at:</p>
        <p>
          Minor Role Inc.<br />
          general@minorrole.com<br /> 
          470-681-8911
        </p>
        <p>By using Watch10k, you acknowledge that you have read and understood these Terms and agree to be bound by them.</p>
      </Section>

    </div>
  );
};

export default Legal;