// src/components/CookieConsentBanner.js

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const BannerContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(11, 11, 10, 0.9);
  color: #F8F8F8;
  padding: 1rem;
  display: ${props => props.show ? 'flex' : 'none'};
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Message = styled.p`
  margin: 0;
  padding-right: 1rem;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
  @media (max-width: 768px) {
    margin-top: 1rem;
  }
`;

const Button = styled.button`
  background-color: ${props => props.primary ? '#D72638' : '#FF9B54'};
  color: #F8F8F8;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.8;
  }
`;

const CookieConsentBanner = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (consent === null) {
      setShow(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    setShow(false);
    // Here you would enable any optional cookies or tracking
  };

  const handleDecline = () => {
    localStorage.setItem('cookieConsent', 'declined');
    setShow(false);
    // Here you would ensure any optional cookies or tracking are disabled
  };

  return (
    <BannerContainer show={show}>
      <Message>
        We use cookies to enhance your experience and analyze our traffic. By clicking "Accept," you consent to our use of cookies. For more information, please read our Privacy Policy.
      </Message>
      <ButtonGroup>
        <Button onClick={handleDecline}>Decline</Button>
        <Button primary onClick={handleAccept}>Accept</Button>
      </ButtonGroup>
    </BannerContainer>
  );
};

export default CookieConsentBanner;