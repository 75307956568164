// src/components/NavBar.js

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FaHome, FaQuestionCircle, FaInstagram, FaTiktok, FaYoutube, FaEnvelope, FaDiscord, FaBars } from 'react-icons/fa';

const Nav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(11, 11, 10, 0.8);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  z-index: 1000;
`;

const NavBrand = styled(Link)`
  font-size: 1.5rem;
  font-weight: bold;
  color: #F8F8F8;
  text-decoration: none;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 1.5rem;

  @media (max-width: 768px) {
    display: none;
  }
`;

const NavLink = styled(Link)`
  color: #F8F8F8;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  transition: color 0.3s ease;

  &:hover {
    color: #FF9B54;
  }
`;

const ExternalLink = styled.a`
  color: #F8F8F8;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  transition: color 0.3s ease;

  &:hover {
    color: #FF9B54;
  }
`;

const HamburgerIcon = styled.div`
  display: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #F8F8F8;

  @media (max-width: 768px) {
    display: block;
  }
`;

const MobileMenu = styled.div`
  display: ${props => props.isOpen ? 'flex' : 'none'};
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: rgba(11, 11, 10, 0.95);
  padding: 1rem;
  backdrop-filter: blur(5px);
`;

const MobileNavLink = styled(NavLink)`
  padding: 0.5rem 0;
`;

const MobileExternalLink = styled(ExternalLink)`
  padding: 0.5rem 0;
`;

const NavBar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <Nav>
      <NavBrand to="/">WATCH10K</NavBrand>
      <NavLinks>
        <NavLink to="/"><FaHome /></NavLink>
        <NavLink to="/faq"><FaQuestionCircle /></NavLink>
        <ExternalLink href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
          <FaInstagram />
        </ExternalLink>
        <ExternalLink href="https://www.tiktok.com" target="_blank" rel="noopener noreferrer">
          <FaTiktok />
        </ExternalLink>
        <ExternalLink href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
          <FaYoutube />
        </ExternalLink>
        <ExternalLink href="mailto:jun@minorrole.com">
          <FaEnvelope />
        </ExternalLink>
        <ExternalLink href="https://discord.gg/Yu3RTzTD6M" target="_blank" rel="noopener noreferrer">
          <FaDiscord />
        </ExternalLink>
      </NavLinks>
      <HamburgerIcon onClick={toggleMobileMenu}>
        <FaBars />
      </HamburgerIcon>
      <MobileMenu isOpen={isMobileMenuOpen}>
        <MobileNavLink to="/" onClick={toggleMobileMenu}><FaHome /> Home</MobileNavLink>
        <MobileNavLink to="/faq" onClick={toggleMobileMenu}><FaQuestionCircle /> FAQ</MobileNavLink>
        <MobileExternalLink href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
          <FaInstagram /> Instagram
        </MobileExternalLink>
        <MobileExternalLink href="https://www.tiktok.com" target="_blank" rel="noopener noreferrer">
          <FaTiktok /> TikTok
        </MobileExternalLink>
        <MobileExternalLink href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
          <FaYoutube /> YouTube
        </MobileExternalLink>
        <MobileExternalLink href="mailto:jun@minorrole.com">
          <FaEnvelope /> Email
        </MobileExternalLink>
        <MobileExternalLink href="https://discord.gg/Yu3RTzTD6M" target="_blank" rel="noopener noreferrer">
          <FaDiscord /> Discord
        </MobileExternalLink>
      </MobileMenu>
    </Nav>
  );
};

export default NavBar;