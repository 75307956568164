// src/components/SecondViewMock.js

import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const ViewWrapper = styled.div`
  background-color: #0B0B0A;
  border-radius: 10px;
  padding: 1rem;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  animation: ${fadeIn} 1s ease-out;

  @media (min-width: 768px) {
    padding: 1.5rem;
  }
`;

const Title = styled.h2`
  color: #F8F8F8;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
`;

const GraphContainer = styled.div`
  flex: 1;
  position: relative;
  height: 150px;

  @media (min-width: 768px) {
    height: 200px;
  }
`;

const SVG = styled.svg`
  width: 100%;
  height: 100%;
`;

const GridLine = styled.line`
  stroke: rgba(255, 255, 255, 0.1);
  stroke-width: 1;
`;

const DataLine = styled.polyline`
  fill: none;
  stroke: #F8F8F8;
  stroke-width: 2;
`;

const DataPoint = styled.circle`
  fill: #F8F8F8;
`;

const YAxisLabel = styled.text`
  fill: #F8F8F8;
  font-size: 8px;
  text-anchor: end;

@media (min-width: 768px) {
    font-size: 10px;
  }
`;

const XAxisLabel = styled.text`
  fill: #F8F8F8;
  font-size: 8px;
  text-anchor: middle;

  @media (min-width: 768px) {
    font-size: 10px;
  }
`;

const StatsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  color: #F8F8F8;
  font-size: 0.8rem;
  margin-top: 0.5rem;

  @media (min-width: 768px) {
    font-size: 1rem;
    margin-top: 0.75rem;
  }
`;

const SecondViewMock = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const generateData = () => {
      const today = new Date();
      return Array(6).fill().map((_, index) => {
        const date = new Date(today);
        date.setDate(date.getDate() - (5 - index));
        return {
          date: date.toISOString().split('T')[0],
          steps: Math.floor(Math.random() * 5000) + 5000
        };
      });
    };

    setData(generateData());

    const interval = setInterval(() => {
      setData(prevData => {
        const newData = [...prevData.slice(1)];
        const lastDate = new Date(newData[newData.length - 1].date);
        lastDate.setDate(lastDate.getDate() + 1);
        newData.push({
          date: lastDate.toISOString().split('T')[0],
          steps: Math.floor(Math.random() * 5000) + 5000
        });
        return newData;
      });
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const maxSteps = Math.max(...data.map(d => d.steps), 10000);
  const minSteps = 0;
  const stepRange = maxSteps - minSteps;

  const getY = (steps) => {
    return 180 - ((steps - minSteps) / stepRange) * 180;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}`;
  };

  const calculateAverage = () => {
    const sum = data.reduce((acc, curr) => acc + curr.steps, 0);
    return Math.round(sum / data.length);
  };

  return (
    <ViewWrapper>
      <Title>Your step trend</Title>
      <GraphContainer>
        <SVG viewBox="0 0 300 220">
          {[0, 1, 2, 3, 4].map(i => (
            <GridLine key={i} x1="30" y1={i * 45} x2="300" y2={i * 45} />
          ))}

          {[maxSteps, maxSteps * 0.75, maxSteps * 0.5, maxSteps * 0.25, minSteps].map((steps, i) => (
            <YAxisLabel key={i} x="25" y={i * 45 + 5}>
              {steps >= 1000 ? `${Math.round(steps / 1000)}k` : steps}
            </YAxisLabel>
          ))}

          {data.map((d, i) => (
            <XAxisLabel key={i} x={30 + i * 54} y="210">
              {formatDate(d.date)}
            </XAxisLabel>
          ))}

          <DataLine points={data.map((d, i) => `${30 + i * 54},${getY(d.steps)}`).join(' ')} />

          {data.map((d, i) => (
            <DataPoint key={i} cx={30 + i * 54} cy={getY(d.steps)} r="4" />
          ))}
        </SVG>
      </GraphContainer>
      <StatsContainer>
        <span>Last {data.length} days</span>
        <span>Avg: {calculateAverage()} steps</span>
      </StatsContainer>
    </ViewWrapper>
  );
};

export default SecondViewMock;