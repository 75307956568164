// src/components/TrendAnalysis.js

import React from 'react';
import styled from 'styled-components';
import SecondViewMock from './SecondViewMock';

const TrendSection = styled.div`
  background-color: #1A1A1A;
  border-radius: 10px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(248, 248, 248, 0.1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 2rem;
  border: 1px solid rgba(248, 248, 248, 0.3);

  @media (min-width: 768px) {
    width: 48%;
    margin-bottom: 0;
  }

  @media (min-width: 1200px) {
    width: 49%;
  }
`;

const TrendContent = styled.div`
  margin-bottom: 1.5rem;
  width: 100%;
`;

const SectionTitle = styled.h2`
  color: #FEFEFE;
  font-size: 1.5rem;
  margin-bottom: 0.75rem;

  @media (min-width: 768px) {
    font-size: 1.75rem;
  }
`;

const StyledSecondViewMock = styled(SecondViewMock)`
  width: 100%;
  height: auto;
`;

const TrendAnalysis = () => {
  return (
    <TrendSection className="fade-in">
      <TrendContent>
        <SectionTitle>Trend analysis</SectionTitle>
        <p>
          Track your daily steps over the past week. Understanding your step trends can help you set realistic goals and stay motivated.
        </p>
      </TrendContent>
      <StyledSecondViewMock />
    </TrendSection>
  );
};

export default TrendAnalysis;