// src/components/FAQ.js

import React from 'react';
import styled from 'styled-components';

const FAQContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  padding-top: calc(2rem + 70px);
`;

const Title = styled.h1`
  color: #F8F8F8;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 2rem;
`;

const Section = styled.section`
  margin-bottom: 2rem;
`;

const SectionTitle = styled.h2`
  color: #FF9B54;
  font-size: 1.75rem;
  margin-bottom: 1rem;
`;

const Question = styled.h3`
  color: #F8F8F8;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
`;

const Answer = styled.p`
  color: #F8F8F8;
  font-size: 1rem;
  margin-bottom: 1rem;
`;

const FAQ = () => {
  return (
    <FAQContainer>
      <Title>Frequently Asked Questions</Title>

      <Section>
        <SectionTitle>General Questions</SectionTitle>
        <Question>Q: What is Watch10k?</Question>
        <Answer>A: Watch10k is a watchOS app designed to help you track your daily steps and motivate you to reach a goal of 10,000 steps per day.</Answer>

        <Question>Q: Is Watch10k free?</Question>
        <Answer>A: Information about pricing is not available at this time. Please check the App Store for the most up-to-date pricing details.</Answer>

        <Question>Q: Which Apple Watch models are compatible with Watch10k?</Question>
        <Answer>A: Watch10k should be compatible with all Apple Watch models that can run the latest watchOS. For specific compatibility, please check the App Store listing.</Answer>
      </Section>

      <Section>
        <SectionTitle>Features</SectionTitle>
        <Question>Q: How does Watch10k track my steps?</Question>
        <Answer>A: Watch10k uses the built-in sensors of your Apple Watch and integrates with HealthKit to accurately count your steps throughout the day.</Answer>

        <Question>Q: Does Watch10k work when I'm not actively using the app?</Question>
        <Answer>A: Yes, Watch10k continues to track your steps in the background and updates periodically to ensure your step count is current.</Answer>

        <Question>Q: What are complications and how do I use them?</Question>
        <Answer>A: Complications are small elements on your watch face that display app information. Watch10k offers various complications to show your current step count and progress directly on your watch face.</Answer>

        <Question>Q: Can I see my step history?</Question>
        <Answer>A: Yes, Watch10k provides a graph view showing your step counts for the last six days.</Answer>
      </Section>

      <Section>
        <SectionTitle>Data and Privacy</SectionTitle>
        <Question>Q: Does Watch10k have access to all my health data?</Question>
        <Answer>A: No, Watch10k only requests access to your step count data. It does not access any other health information. Importantly, while the app uses this data to provide its functionality, the app developers do not have access to your personal health data. Your information remains on your device and, if you use iCloud, in your personal iCloud account. The developers can't see or access this data.</Answer>

        <Question>Q: Is my data secure?</Question>
        <Answer>A: Yes, your data is securely stored on your device and in iCloud (if you use iCloud). We use Apple's CloudKit for syncing, which ensures your data is encrypted and protected.</Answer>
      </Section>

      <Section>
        <SectionTitle>Streaks and Goals</SectionTitle>
        <Question>Q: What is a streak?</Question>
        <Answer>A: A streak is the number of consecutive days you've reached your step goal (10,000 steps).</Answer>

        <Question>Q: What happens if I miss a day?</Question>
        <Answer>A: If you don't reach 10,000 steps in a day, your streak will reset to 1 the next day you achieve the goal.</Answer>

        <Question>Q: Can I change the daily step goal?</Question>
        <Answer>A: Currently, the goal is set to 10,000 steps and cannot be changed.</Answer>
      </Section>

      <Section>
        <SectionTitle>Notifications</SectionTitle>
        <Question>Q: Does Watch10k send notifications?</Question>
        <Answer>A: Yes, Watch10k sends motivational notifications to encourage you to reach your daily step goal.</Answer>

        <Question>Q: When are notifications sent?</Question>
        <Answer>A: By default, a notification is sent at 7 PM if you haven't reached 75% of your daily goal (7,500 steps).</Answer>
      </Section>

      <Section>
        <SectionTitle>Troubleshooting</SectionTitle>
        <Question>Q: The app isn't counting my steps accurately. What should I do?</Question>
        <Answer>A: Ensure that your Apple Watch is worn correctly and that you've granted the necessary permissions to the app. If issues persist, try restarting your Apple Watch.</Answer>

        <Question>Q: Why isn't my streak updating?</Question>
        <Answer>A: Make sure you have an active internet connection for syncing. If the problem continues, check if there are any reported iCloud outages.</Answer>

        <Question>Q: How do I report a bug or suggest a feature?</Question>
        <Answer>A: You have two options to report bugs or suggest features:
          1. Through the Apple App Store: You can leave a review or report issues directly on the Watch10k app page in the App Store.
          2. On our website: Visit Watch10K.com to submit bug reports or feature suggestions through our dedicated support channels.</Answer>
      </Section>
    </FAQContainer>
  );
};

export default FAQ;