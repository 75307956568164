// src/components/PromotionalCard.js

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Card = styled.div`
  background-color: #1A1A1A;
  border-radius: 10px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(248, 248, 248, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 350px;
  height: auto;
  border: 1px solid rgba(248, 248, 248, 0.3);

  @media (min-width: 768px) {
    width: 48%;
  }

  @media (min-width: 1200px) {
    width: 24%;
  }
`;

const CardSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 0 0.5rem;
`;

const CardTitle = styled.h2`
  color: #FEFEFE;
  font-size: 1.5rem;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    font-size: 1.75rem;
  }
`;

const CardContent = styled.p`
  margin-bottom: 1rem;
  word-wrap: break-word;
  overflow-wrap: break-word;
  flex-grow: 1;
  padding: 0 1.5rem;
`;

const CardButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
  margin-top: 1rem;
  padding: 2rem 0 0 -2rem;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
`;

const CardButton = styled.button`
  background-color: ${props => props.primary ? '#D72638' : '#FF9B54'};
  color: #F8F8F8;
  border: none;
  padding: 0.5rem;
  border-radius: 2rem;
  font-size: 0.8rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  width: 130px;
  height: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    opacity: 0.9;
  }

  svg {
    margin-right: 0.5rem;
    font-size: 1.25rem;
  }

  @media (min-width: 1200px) {
    font-size: 0.9rem;
  }
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const PromotionalCard = ({ title, content, buttons }) => {
  return (
    <Card className="fade-in">
      <CardSection>
        <CardTitle>{title}</CardTitle>
        <CardContent>{content}</CardContent>
        <CardButtonContainer>
          {buttons.map((button, index) => (
            button.to ? (
              <NavLink key={index} to={button.to} style={{ display: 'contents' }}>
                <CardButton>
                  {button.icon}
                  {button.text}
                </CardButton>
              </NavLink>
            ) : (
              <CardButton key={index} as="a" href={button.href} target="_blank" rel="noopener noreferrer">
                {button.icon}
                {button.text}
              </CardButton>
            )
          ))}
        </CardButtonContainer>
      </CardSection>
    </Card>
  );
};

export default PromotionalCard;