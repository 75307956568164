// src/components/AppleWatchUltraMock.js

import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const rotateIn = keyframes`
  from {
    transform: rotate(-10deg) scale(0.9);
    opacity: 0;
  }
  to {
    transform: rotate(0) scale(1);
    opacity: 1;
  }
`;

const WatchContainer = styled.div`
  width: 90vw;
  height: 110vw;
  max-width: 600px;
  max-height: 740px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0B0B0A;
  animation: ${rotateIn} 1s ease-out forwards;

  @media (min-width: 768px) {
    width: 45vw;
    height: 55vw;
  }
`;
const WatchOutline = styled.div`
  width: 100%;
  height: 100%;
  border: 0.5vw solid #F8F8F8;
  border-radius: 10%;
  position: absolute;
`;

const WatchFrame = styled.div`
  width: 92%;
  height: 92%;
  border: 0.3vw solid #F8F8F8;
  border-radius: 8%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  background-color: #0B0B0A;
  padding: 20px;
`;

const WatchCrown = styled.div`
  width: 5%;
  height: 15%;
  border: 0.3vw solid #F8F8F8;
  position: absolute;
  right: -4%;
  top: 25%;
  border-radius: 1vw 1vw 1vw 1vw;
`;

const WatchButton = styled.div`
  width: 4%;
  height: 25%;
  border: 0.9vw solid #F8F8F8;
  position: absolute;
  right: -4%;
  top: 52%;
  border-radius: 0vw 1vw 1vw 0vw;
`;

const ActionButton = styled.div`
  width: 2%;
  height: 30%;
  border: 0.3vw solid #F8F8F8;
  position: absolute;
  left: -1%;
  top: 55%;
  border-radius: 1vw 0 0 1vw;
`;

const StreakBadge = styled.div`
  align-self: flex-start;
  background-color: rgba(215, 38, 56, 0.5);
  color: #F8F8F8;
  font-weight: bold;
  font-size: 0.8rem;
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    font-size: 1rem;
    padding: 0.5rem 1rem;
  }
`;

const EnhancedCircularProgressView = styled.div`
  width: 80%;
  height: 80%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProgressCircle = styled.svg`
  transform: rotate(-90deg);
  width: 100%;
  height: 100%;
`;

const Background = styled.circle`
  fill: none;
  stroke: rgba(255, 155, 84, 0.3);
  stroke-width: 20;
`;

const Progress = styled.circle`
  fill: none;
  stroke: url(#gradient);
  stroke-width: 25;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease;
`;

const CenterText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #F8F8F8;
`;

const StepsToGo = styled.div`
  font-size: 1rem;
  font-weight: 800;

  @media (min-width: 768px) {
    font-size: 3rem;
  }
`;


const StepsLabel = styled.div`
  font-size: 0.7rem;
  opacity: 0.7;

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`;

const MotivationalMessage = styled.p`
  color: #F8F8F8;
  font-size: 0.8rem;
  text-align: center;
  margin-top: 10px;

  @media (min-width: 768px) {
    font-size: 2rem;
  }
`;

const AppleWatchUltraMock = () => {
  const [stepCount, setStepCount] = useState(0);
  const [streak, setStreak] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setStepCount(prevCount => {
        const newCount = prevCount + 100;
        if (newCount >= 10000) {
          setStreak(prevStreak => prevStreak + 1);
          return 0;
        }
        return newCount;
      });
    }, 100);

    return () => clearInterval(interval);
  }, []);

  const progress = Math.min(stepCount / 10000, 1);
  const circumference = 2 * Math.PI * 85;
  const strokeDashoffset = circumference - progress * circumference;

  const getMotivationalMessage = () => {
    if (stepCount >= 10000) return "You did it! Keep going!";
    if (stepCount > 7500) return "Almost there. Finish strong!";
    if (stepCount > 5000) return "The finish line is in sight!";
    if (stepCount > 2500) return "You're on fire now!";
    return "Momentum is building!";
  };

  return (
    <WatchContainer>
      <WatchOutline />
      <WatchFrame>
        <StreakBadge>Day {streak}</StreakBadge>
        <EnhancedCircularProgressView>
          <ProgressCircle viewBox="0 0 200 200">
            <defs>
              <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" stopColor="#D72638" />
                <stop offset="100%" stopColor="#FF9B54" />
              </linearGradient>
            </defs>
            <Background cx="100" cy="100" r="85" />
            <Progress 
              cx="100" 
              cy="100" 
              r="85"
              strokeDasharray={circumference}
              strokeDashoffset={strokeDashoffset}
            />
          </ProgressCircle>
          <CenterText>
            <StepsToGo>{stepCount >= 10000 ? stepCount - 10000 : 10000 - stepCount}</StepsToGo>
            <StepsLabel>{stepCount >= 10000 ? 'steps over goal' : 'steps to go'}</StepsLabel>
          </CenterText>
        </EnhancedCircularProgressView>
        <MotivationalMessage>{getMotivationalMessage()}</MotivationalMessage>
      </WatchFrame>
      <WatchButton />
      <WatchCrown />
      <ActionButton />
    </WatchContainer>
  );
};

export default AppleWatchUltraMock;