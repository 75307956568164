// src/App.js

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styled, { createGlobalStyle, keyframes } from 'styled-components';
import NavBar from './components/NavBar';
import HeroSection from './components/HeroSection';
import TrendAnalysis from './components/TrendAnalysis';
import PromotionalCard from './components/PromotionalCard';
import FAQ from './components/FAQ';
import Legal from './components/Legal';
import Footer from './components/Footer';
import CookieConsentBanner from './components/CookieConsentBanner';
import { FaEnvelope, FaDiscord } from 'react-icons/fa';
import { RiQuestionLine } from 'react-icons/ri';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

  body {
    margin: 0;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #0B0B0A;
    color: #F8F8F8;
    font-size: 16px;
    line-height: 1.5;
  }

  * {
    box-sizing: border-box;
  }

  .fade-in {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  }

  .fade-in.visible {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
`;

const AnalysisSection = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem;

  @media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 2rem 2rem;
  }

  @media (min-width: 1200px) {
    margin-top: 3rem;
  }
`;

function App() {
  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll('.fade-in');
      elements.forEach((el) => {
        const rect = el.getBoundingClientRect();
        const isVisible = rect.top <= window.innerHeight * 0.75;
        if (isVisible) {
          el.classList.add('visible');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Router>
      <Helmet>
        <title>WATCH10K - Track Your Steps, Stay Motivated</title>
        <meta name="description" content="WATCH10K is a watchOS app designed to help you track your daily steps and motivate you to reach a goal of 10,000 steps per day." />
        <meta name="keywords" content="step tracker, fitness app, Apple Watch, health, motivation" />
        <meta name="author" content="WATCH10K Team" />
        <meta property="og:title" content="WATCH10K - Track Your Steps, Stay Motivated" />
        <meta property="og:description" content="WATCH10K is a watchOS app designed to help you track your daily steps and motivate you to reach a goal of 10,000 steps per day." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.watch10k.com" />
        <meta property="og:image" content="https://www.watch10k.com/og-image.jpg" />
      </Helmet>
      <GlobalStyle />
      <AppWrapper>
        <NavBar />
        <Routes>
          <Route path="/" element={
            <>
              <Helmet>
                <title>WATCH10K - Home</title>
                <meta name="description" content="Track your steps and stay motivated with WATCH10K, the ultimate watchOS app for fitness enthusiasts." />
              </Helmet>
              <HeroSection />
              <AnalysisSection>
                <TrendAnalysis />
                <PromotionalCard
                  title="Boost Your Fitness Journey"
                  content="Take your health to the next level with fasting-companion.com. Calculate your TDEE and generate personalized fasting schedules directly to your calendar."
                  buttons={[
                    {
                      text: "Start Fasting",
                      href: "https://fasting-companion.com",
                    }
                  ]}
                />
                <PromotionalCard
                  title="Frequently Asked Questions"
                  content="Have questions about WATCH10K or fasting? Check out our FAQ page for answers to common queries and helpful information."
                  buttons={[
                    {
                      text: "FAQ",
                      icon: <RiQuestionLine />,
                      to: "/faq"
                    },
                    {
                      text: "Talk on Discord",
                      icon: <FaDiscord />,
                      href: "https://discord.gg/Yu3RTzTD6M"
                    }
                  ]}
                />
              </AnalysisSection>
            </>
          } />
          <Route path="/faq" element={
            <>
              <Helmet>
                <title>WATCH10K - Frequently Asked Questions</title>
                <meta name="description" content="Find answers to common questions about WATCH10K, our step tracking app for Apple Watch." />
              </Helmet>
              <FAQ />
            </>
          } />
          <Route path="/legal" element={
            <>
              <Helmet>
                <title>WATCH10K - Legal Information</title>
                <meta name="description" content="Read our privacy policy and terms of use for WATCH10K, the step tracking app for Apple Watch." />
              </Helmet>
              <Legal />
            </>
          } />
        </Routes>
        <Footer />
        <CookieConsentBanner />
      </AppWrapper>
    </Router>
  );
}

export default App;