import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <Link to="/legal#privacy-policy-title">Privacy Policy</Link>
        <Link to="/legal#terms-of-use-title">Terms of Use</Link>
        <a href="mailto:jun@minorrole.com">Contact Us</a>
      </div>
    </footer>
  );
};

export default Footer;