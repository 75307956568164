// src/components/HeroSection.js

import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FaApple, FaDiscord } from 'react-icons/fa';
import AppleWatchUltraMock from './AppleWatchUltraMock';

const slideIn = keyframes`
  from { transform: translateX(50px); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
`;

const MainSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 2rem;
  padding-top: calc(2rem + 70px); // Add extra padding to account for the NavBar

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 0 4rem;
    padding-top: calc(4rem + 70px); // Add extra padding to account for the NavBar
  }

  @media (min-width: 1200px) {
    min-height: auto;
    padding-top: calc(4rem + 70px); // Add extra padding to account for the NavBar
    padding-bottom: 2rem;
  }
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-bottom: 2rem;
  padding: 0 2rem;

  @media (min-width: 768px) {
    width: 50%;
    margin-bottom: 0;
    padding: 0;
  }
`;

const WatchSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation: ${slideIn} 1s ease-out 0.5s forwards;

  @media (min-width: 768px) {
    width: 50%;
  }
`;

const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Title = styled.h1`
  font-size: 3rem;
  color: #F8F8F8;
  margin: 0 1rem 1rem;
  line-height: 1.1;

  @media (min-width: 768px) {
    font-size: 4.5rem;
  }
`;

const Subtitle = styled.p`
  font-size: 1.25rem;
  color: #F8F8F8;
  margin: 0 1rem 2rem;

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`;

const glowAnimation = keyframes`
  0% { box-shadow: 0 0 5px rgba(215, 38, 56, 0.5), 0 0 10px rgba(215, 38, 56, 0.5); }
  50% { box-shadow: 0 0 20px rgba(215, 38, 56, 0.8), 0 0 30px rgba(215, 38, 56, 0.8); }
  100% { box-shadow: 0 0 5px rgba(215, 38, 56, 0.5), 0 0 10px rgba(215, 38, 56, 0.5); }
`;

const Button = styled.button`
  background-color: ${props => props.primary ? '#D72638' : '#FF9B54'};
  color: #F8F8F8;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 2rem;
  font-size: 1rem;
  cursor: pointer;
  margin-right: 1rem;
  margin-bottom: 1rem;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  animation: ${glowAnimation} 6s infinite;

  &:before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(
      circle,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0) 70%
    );
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover:before {
    opacity: 1;
  }

  svg {
    margin-right: 0.5rem;
    font-size: 1.25rem;
  }

  @media (min-width: 768px) {
  font-size: 1.125rem;
    padding: 1rem 2rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const HeroSection = () => {
  return (
    <MainSection>
      <LeftColumn>
        <TitleSection>
          <Title>WATCH10K</Title>
          <Subtitle>Track your steps and stay motivated</Subtitle>
          <ButtonContainer>
            <Button primary as="a" href="https://apps.apple.com/us/app/watch10k/id6639619951" target="_blank" rel="noopener noreferrer">
              <FaApple />
              Download now
            </Button>
            <Button as="a" href="https://discord.gg/Yu3RTzTD6M" target="_blank" rel="noopener noreferrer">
              <FaDiscord />
              Talk on Discord
            </Button>
          </ButtonContainer>
        </TitleSection>
      </LeftColumn>
      <WatchSection>
        <AppleWatchUltraMock />
      </WatchSection>
    </MainSection>
  );
};

export default HeroSection;